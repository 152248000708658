<template>
  <div>
    <a-select
      v-model:value="state"
      dropdown-class-name="state-max-content-width"
      style="width: 100%"
      :filter-option="filterOption"
      :size="size"
      :placeholder="placeholder"
      show-search
      @change="handleChange"
    >
      <a-select-option v-for="c in states" :key="c.name" :value="c.value">
        {{ c.name }}
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
import { getStatesByCountry } from '@/plugins/countriesStatesHelper';

export default {
  name: 'InputState',
  props: {
    value: {
      type: String,
      default() {
        return undefined;
      },
    },
    countryCode: {
      type: String,
      default() {
        return null;
      },
    },
    size: {
      type: String,
      default() {
        return 'large';
      },
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    valueLocale: {
      type: [String, null],
      default: null,
    },
  },
  emits: ['change', 'update:value'],
  data() {
    return {
      state: '',
      states: [],
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        this.state = nv;
      },
    },
    countryCode: {
      immediate: true,
      handler(nv) {
        this.states = getStatesByCountry(nv, this.valueLocale);
      },
    },
  },
  methods: {
    filterOption(input, option) {
      return option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    handleChange() {
      console.log(this.state);
      this.$emit('change', this.state);
      this.$emit('update:value', this.state);
    },
  },
};
</script>

<style>
.state-max-content-width {
  min-width: 200px !important;
}
@media screen and (max-width: 400px) {
  .state-max-content-width {
    min-width: auto !important;
  }
}
</style>
