import number from '@/filters/number';
import { getAreaPrefecture } from '@/plugins/countriesStatesHelper';

/**
 * Remove falsy values and parse years.
 *
 * @export
 * @param {Array} [years=[]]
 *
 * @returns {Array}
 */
export function sanitizeYears(years = []) {
  return years.reduce((acc, v) => {
    if (v) {
      acc.push(parseInt(v, 10));
    }

    return acc;
  }, []);
}

/**
 * Round the given number.
 *
 * @export
 * @param {Number} n
 * @param {Fraction} [fraction=2]
 *
 * @returns {Number}
 */
export function round(n, fraction = 2) {
  return number(n, {
    useGrouping: false,
    maximumFractionDigits: fraction,
  });
}

/**
 * Extract base query to be used to spdb api.
 *
 * @export
 * @param {Object} simulation
 * @param {Array} [years=[]]
 *
 * @returns {Object}
 */
export function spdbBaseQuery(simulation, years = []) {
  return {
    years: sanitizeYears(years),
    prefecture: getAreaPrefecture(simulation.prefecture),
    areaSmall: simulation.areaSmall,
    areaDetail: simulation.areaDetail,
  };
}

/**
 * Helper to result from division to 0.
 *
 * @export
 * @param {Number|Infinity} n
 *
 * @returns {Number}
 */
export function num(n) {
  return Number.isFinite(n) ? n : 0;
}

/**
 * Convert percentage whole number to decimal.
 *
 * @export
 * @param {Number} n
 *
 * @returns {Number}
 */
export function convertPercent(n) {
  return num(n / 100);
}

/**
 * Replace new lines and double spaces with single spaces.
 *
 * @private
 * @param {String} [str='']
 *
 * @returns {String}
 */
function collapseWhitespace(str = '') {
  return str.replace(/\n/g, '')
    .replace(/\s+/g, ' ')
    .trim();
}
/**
 * logger utility function specifically
 * for simulation calculation.
 *
 * @param {String|Object} label
 * @param {any} result
 * @param {Object|Null} [calculation=null]
 * @param {String} calculation.formula
 * @param {String} calculation.values
 */
export function log(label, result = null, calculation = null) {
  let text = label;

  if (result !== null || calculation !== null) {
    const { formula, values } = calculation;
    const calc = formula && values
      ? `${collapseWhitespace(formula)} | ${collapseWhitespace(values)} =`
      : '';

    text = `[${label}]: ${calc} ${result}`;
  }

  console.log(text);
}
